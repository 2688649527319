import VerticalSwipe from "@/ts/ImageViewer/Transform/VerticalSwipe";
import Zoom from "@/ts/ImageViewer/Transform/Zoom";
import MoveInZoom from "@/ts/ImageViewer/Transform/MoveInZoom";
import ImageTransformer from "@/ts/ImageViewer/Transform/ImageTransformer";

export default class ImageViewer {
    private touch = {
        initial: {
            x: 0,
            y: 0,
            scale: 1,
            distance: 0,
            midPointX: 0,
            midPointY: 0,
            offsetX: 0,
            offsetY: 0
        },
        current: {
            scale: 1,
            offsetX: 0,
            offsetY: 0,
            midPointX: 0,
            midPointY: 0
        },
        last: {
            eventType: null as (null | 'moveInZoom' | 'zoom' | 'swipe'),
            midPointX: 0,
            midPointY: 0
        }
    };

    private original = {
        bodyStyle: {
            overscrollBehaviorY: '',
            overflow: '',
        },
    }

    private elements = {
        container: null as HTMLElement | null,
        image: null as HTMLImageElement | null,
    };

    private transformers: ImageTransformer[] = [];
    private static instance: ImageViewer;
    private static creating: boolean = false;

    private constructor() {
        if (!ImageViewer.creating) {
            throw new Error("You must use ImageViewer.getInstance() to create an instance.");
        }
        this.transformers = [
            new VerticalSwipe(this.touch, this.elements),
            new MoveInZoom(this.touch, this.elements),
            new Zoom(this.touch, this.elements),
        ];
    }

    static getInstance(): ImageViewer {
        if (!ImageViewer.instance) {
            ImageViewer.creating = true;
            ImageViewer.instance = new ImageViewer();
            ImageViewer.creating = false;
        }
        return ImageViewer.instance;
    }

    public show(src: string) {
        this.elements.container?.classList.add('showing', 'show');
        setTimeout(() => {
            this.elements.container?.classList.remove('showing');
        }, 400);
        this.elements.image?.setAttribute('src', src);
        if (!this.elements.container) return;
        this.disableUselessEvents()
        this.transformers.forEach(transformer => {
           transformer.init();
        })
    }


    public init(container: HTMLElement, image: HTMLImageElement) {
        this.elements.container = container;
        this.elements.image = image;
    }

    private disableUselessEvents() {
        this.original.bodyStyle = {
            overscrollBehaviorY: document.body.style.overscrollBehaviorY,
            overflow: document.body.style.overflow,
        };
        document.body.style.overscrollBehaviorY = 'none'
        document.body.style.overflow = 'hidden';
    }



    private onTouchEnd(event: TouchEvent) {
        const isSingleTouch = event.touches.length < 2;

        if (isSingleTouch) {
            this.resetInitialValues();
            this.resetCurrentScale();
        }
    }

    private resetInitialValues() {
        this.touch.initial.distance = 0;
        this.touch.initial.scale = 1; // сбросить начальный масштаб до 1
    }

    private resetCurrentScale() {
        if (this.touch.current.scale < 1) {
            this.touch.current.scale = 1; // сбросить текущий масштаб до 1
        }
    }

    public destroy(): void {
        // Восстанавливаем стандартные значения
        document.body.style.overscrollBehaviorY = this.original.bodyStyle.overscrollBehaviorY;
        document.body.style.overflow = this.original.bodyStyle.overflow;
        // Удаляем обработчики событий
        if (this.elements.container) {
            //очистить классы
            this.elements.container.classList.remove('closing', 'showing', 'show');
            this.elements.image!.style.removeProperty('transform');
            this.elements.container.style.removeProperty('background-color');
            this.transformers.forEach(transformer => {
                transformer.destroy();
            })
        }
    }
}
