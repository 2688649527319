<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref} from 'vue';
import AudioSlider from "@/components/UI/AudioPlayer/AudioSlider.vue";

const props = defineProps({
  audio: {
    type: Audio,
    required: true,
  },
});

const currentTime = ref('00:00');
const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
const duration = ref(formatTime(props.audio!.duration))
const progress = ref(0);

onMounted(() => {
  props.audio!.addEventListener('loadedmetadata', () => {
    duration.value = formatTime(props.audio!.duration);
  });
  props.audio!.addEventListener('timeupdate', updateTime);
});

onUnmounted(() => {
  props.audio!.removeEventListener('timeupdate', updateTime);
});

const updateTime = () => {
  currentTime.value = formatTime(props.audio!.currentTime);
  progress.value = (props.audio!.currentTime / props.audio!.duration) * 100;
};


const changeTime = (event) => {
  props.audio!.currentTime = (event.target.value / 100) * props.audio!.duration;
};
const wrapChangeTime = (value) => {
  props.audio!.currentTime = value * props.audio!.duration;
};
</script>

<template>
  <div class="audio-controller">
    <div><AudioSlider :min="0" :max="100" v-model="progress" @input="changeTime" @wrapinput="wrapChangeTime"/></div>
    <div class="audio-controller__time">{{ currentTime }}</div>
    <div class="audio-controller__duration">{{ duration }}</div>
  </div>
</template>

<style scoped lang="scss">
.audio-controller {
  height: 40px;
  position: relative;
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  &__time{
    position: absolute;
    bottom: -10px;
    left: 0;
  }

  &__duration{
    position: absolute;
    bottom: -10px;
    right: 0;
  }
}

</style>
