<script setup lang="ts">
import AudioWave from "@/components/UI/AudioPlayer/AudioWave.vue";
import AudioPlayerPopup from "@/components/UI/AudioPlayer/AudioPlayerPopup.vue";
import BackgroundAudio from "@/ts/Audio/BackgroundAudio";

const backgroundAudio = BackgroundAudio.getInstance();
const {isShowedPopup, playerMetadata, isPlaying} = backgroundAudio;
const onClick = () => {
  backgroundAudio.showPopup();
}
</script>

<template>
  <div class="background-audio-player">
    <AudioWave v-if="isPlaying" @click="onClick"/>
    <AudioPlayerPopup v-model:show="isShowedPopup" v-if="playerMetadata" v-bind="playerMetadata"/>
  </div>
</template>

<style scoped lang="scss">

</style>
