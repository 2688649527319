<script setup lang="ts">

import AudioController from "@/components/UI/AudioPlayer/AudioController.vue";
import Icon from "@/components/UI/Icon.vue";
import BackgroundAudio, {PlayerMetadata} from "@/ts/Audio/BackgroundAudio";
import {computed, onMounted, onUnmounted} from "vue";

const props = withDefaults(defineProps<PlayerMetadata & {
  theme?: 'light' | 'dark'
}>(), {
  theme: 'light'
});
const backgroundAudio = BackgroundAudio.getInstance();
const {isPlaying} = backgroundAudio;

const iconName = computed(() => isPlaying.value ? 'pause' : 'play');

const onClick = () => {
  isPlaying.value ? backgroundAudio.pause() : backgroundAudio.play();
}
const onEnded = () => {
  backgroundAudio.pause()
}
onMounted(() => {
  backgroundAudio.addEventListener('ended', onEnded);
});
onUnmounted(() => {
  backgroundAudio.removeEventListener('ended', onEnded);
});
const buttonColor = computed(() => props.theme === 'light' ? '#363739' : '#FFFFFF');
</script>

<template>
  <div class="detailed-audio-player">
    <div class="detailed-audio-player__title">{{ title }}</div>
    <div class="detailed-audio-player__controller">
      <AudioController :audio="audio"/>
    </div>
    <div class="detailed-audio-player__button">
      <Icon :size="60" :color="buttonColor" :name="iconName" @click="onClick"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.detailed-audio-player {
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.34px;
  }

  &__controller {
    padding-top: 18px;
    padding-bottom: 41px;
  }

  &__button {
    display: flex;
    justify-content: center;

    > * {
      opacity: 1;
      transition: all 0.3s;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}
</style>
