type EventMap = {
    touchstart: (event: TouchEvent) => void;
    touchmove: (event: TouchEvent) => void;
    touchend: (event: TouchEvent) => void;
};

export default abstract class ImageTransformer {
    protected elements: any;
    private events: EventMap = {
        touchstart : this.onTouchStart.bind(this),
        touchmove: this.onTouchMove.bind(this),
        touchend: this.onTouchEnd.bind(this)
    }

    constructor(elements: any ) {
        this.elements = elements;
    }

    public init(): void {
        Array.from(Object.keys(this.events)).forEach((eventName) => {
            this.elements.container.addEventListener(eventName, this.events[eventName as keyof EventMap]);
        })
    }

    protected onTouchStart(event: TouchEvent): void{

    }

    protected onTouchMove(event: TouchEvent): void{

    }

    protected onTouchEnd(event: TouchEvent): void{

    }

    public destroy(): void {
        Array.from(Object.keys(this.events)).forEach((eventName) => {
            this.elements.container.removeEventListener(eventName, this.events[eventName as keyof EventMap]);
        })
    }
}
