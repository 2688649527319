<template>
  <div class="content">
    <router-view/>
    <BackgroundAudioPlayer/>
    <ImageViewer />
  </div>
</template>

<script setup lang="ts">
import {useHead} from "@vueuse/head";
import BackgroundAudioPlayer from "@/components/UI/AudioPlayer/BackgroundAudioPlayer.vue";
import ImageViewer from "@/components/UI/ImageViewer/ImageViewer.vue";
import TrainingHeader from "@/components/Universe/Training/TrainingHeader.vue";

useHead({
  title: 'Magic moon'
})
</script>

