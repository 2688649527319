import ImageViewer from "@/ts/ImageViewer/ImageViewer";
import ImageTransformer from "@/ts/ImageViewer/Transform/ImageTransformer";

export default class VerticalSwipe extends ImageTransformer{
    private touch: any;
    private lastOffsetY: number = 0;
    private offsetForClose: number = 200;
    constructor(touch: any, elements: any ) {
        super(elements);
        this.touch = touch;
    }


    public onTouchStart(event: TouchEvent) {
        const isSingleTouch = event.touches.length === 1;
        if (!isSingleTouch) {
            return;
        }
        this.touch.initial.x = event.touches[0].clientX;
        this.touch.initial.y = event.touches[0].clientY;
    }

    private updateImageTransform() {
        requestAnimationFrame(() => {
            if (this.elements.image) {
                if (this.touch.last.eventType !== 'moveInZoom') {
                    this.elements.image.style.transform = `scale(${this.touch.current.scale})`;
                }
            }
        });
    }

    public onTouchMove(event: TouchEvent ) {
        const isSingleTouch = event.touches.length === 1;
        const isZoomed = this.touch.current.scale > 1;
        if (isSingleTouch && !isZoomed) {
            this.handleSwipe(event);
        }
    }

    public onTouchEnd(event: TouchEvent) {
        const isSingleTouch = event.touches.length < 2;
        if (isSingleTouch) {
            this.updateImageTransform();
            this.setColor(1);
            if(this.lastOffsetY > this.offsetForClose){
                this.onClose();
            }
        }
    }

    private handleSwipe(event: TouchEvent) {
        if (!this.elements.image) return
        this.touch.last.eventType = 'swipe';
        const currentTouchY = event.touches[0].clientY;
        // Вычисляем новое смещение по Y
        this.touch.current.offsetY = currentTouchY - this.touch.initial.y;

        // Применяем смещение по Y к изображению
        this.elements.image.style.transform = `translateY(${this.touch.current.offsetY}px) scale(${this.touch.current.scale})`;
        const offset = Math.abs(currentTouchY - this.touch.initial.y);
        // Вычисляем прозрачность на основе смещения
        const opacity = Math.min(1, Math.max(0, 1 - Math.abs(offset) / this.offsetForClose));
        this.lastOffsetY = offset;
        // Устанавливаем прозрачность для фона контейнера
        this.setColor(opacity);
    }



    private setColor(opacity: number) {
        if (this.elements.container) {
            this.elements.container.style.backgroundColor = `rgba(35, 37, 41, ${opacity})`;
        }
    }

    private onClose() {
        this.elements.container?.classList.add('closing');
        setTimeout(() => {
            ImageViewer.getInstance().destroy()
        }, 500);
    }

}
