import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from 'axios';
import {applyAuthTokenInterceptor, clearAuthTokens} from "axios-jwt";
import JwtToken from "@/ts/User/Types/JwtToken";
import router from "@/router";
import AuthTokenStorage from "@/ts/User/AuthTokenStorage";
import {applyStorage} from "axios-jwt/src/applyStorage";

type ApiClientOptions = {
    jwt?: boolean;
    baseUrl?: string;
}

export class ApiClient {
    private readonly client: AxiosInstance;

    constructor({jwt = true, baseUrl}: ApiClientOptions = {}) {
        this.client = axios.create({
            baseURL: baseUrl || process.env.VUE_APP_API_ENDPOINT,
        });
        if (jwt) {
            applyStorage(AuthTokenStorage());
            applyAuthTokenInterceptor(this.client, {requestRefresh: this.requestRefresh.bind(this)});
        }
    }

    async requestRefresh(refreshToken: string): Promise<string> {
        let response: any;
        try {
            response = await axios.post<JwtToken>('auth/refresh', {refresh_token: refreshToken});
        } catch (e) {
            await this.redirectToAuth();
        }
        return response.token
    }

    private async redirectToAuth() {
        await clearAuthTokens();
        await router.push({name: 'auth'})
    }

    async get<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
        let response: any;
        try {
            response = await this.client.get<T>(`${path}`, config);
        } catch (e) {
            if ((e as AxiosError).response?.status === 401) {
                await this.redirectToAuth();
            } else {
                throw e;
            }
        }
        return response.data;
    }

    async post<T>(path: string, body?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.client.post<T>(`${path}`, body, config);
        return response.data;
    }

    async put<T>(path: string, body: any): Promise<T> {
        const response = await this.client.put<T>(`${path}`, body);
        return response.data;
    }

    async delete<T>(path: string): Promise<T> {
        const response = await this.client.delete<T>(`${path}`);
        return response.data;
    }
}

export default new ApiClient();
