<script setup lang="ts">

import DetailedAudioPlayer from "@/components/UI/AudioPlayer/DetailedAudioPlayer.vue";

defineProps<PlayerMetadata>();
import SplitPopup from "@/components/UI/Pupup/SplitPopup.vue";
import {computed, onMounted, onUnmounted} from "vue";
import BackgroundAudio, {PlayerMetadata} from "@/ts/Audio/BackgroundAudio";
import CDN from "@/ts/Utility/CDN";
import AudioRepository from "@/ts/Audio/Repository/AudioRepository";

const backgroundAudio = BackgroundAudio.getInstance();
const {isPlaying} = backgroundAudio;
const iconName = computed(() => isPlaying.value ? 'pause' : 'play');


const onClick = () => {
  isPlaying.value ? backgroundAudio.pause() : backgroundAudio.play();
}
const onEnded = async () => {
  backgroundAudio.pause()
  const isMeditationListened = backgroundAudio.playerMetadata.value?.type === 'meditations';
  if(isMeditationListened) {
    await AudioRepository.saveMeditationListenedEvent(backgroundAudio.playerMetadata.value?.id!);
  }
}
const onCloseButtonClick = () => {
  backgroundAudio.pause();
}
onMounted(() => {
  backgroundAudio.addEventListener('ended', onEnded);
});
onUnmounted(() => {
  backgroundAudio.removeEventListener('ended', onEnded);
});
</script>

<template>
  <SplitPopup fixed-content-size @closeButtonClick="onCloseButtonClick">
    <div class="audio-player__cover">
      <img :src="CDN.createUrl(image)" alt="cover"/>
    </div>
    <template #footer class="audio-player__footer">
      <div class="audio-player__footer">
        <DetailedAudioPlayer :image="image" :title="title" :audio="audio"/>
      </div>
    </template>
  </SplitPopup>
</template>

<style scoped lang="scss">
.audio-player {
  &__cover {
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__footer {
    height: 248px;
    width: 100%;
    display: flex;
    padding: 30px 40px 60px 40px;
    box-sizing: border-box;
    flex-direction: column;
  }

}
</style>
