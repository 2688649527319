import ImageTransformer from "@/ts/ImageViewer/Transform/ImageTransformer";

export default class MoveInZoom extends ImageTransformer {
    private touch: any;

    constructor(touch: any, elements: any) {
        super(elements);
        this.touch = touch;
    }


    protected onTouchMove(event: TouchEvent) {
        const isSingleTouch = event.touches.length === 1;
        const isZoomed = this.touch.current.scale > 1;
        if(isSingleTouch && isZoomed){
            if(!this.elements.image) return
            this.touch.last.eventType = 'moveInZoom';
            const currentTouchX = event.touches[0].clientX;
            const currentTouchY = event.touches[0].clientY;

            // Вычисляем новые смещения
            this.touch.current.offsetX = currentTouchX - this.touch.initial.x;
            this.touch.current.offsetY = currentTouchY - this.touch.initial.y;

            // Применяем смещения к изображению
            this.elements.image.style.transform = `translate(${this.touch.current.offsetX}px, ${this.touch.current.offsetY}px) scale(${this.touch.current.scale})`;

        }
    }
}
