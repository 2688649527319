import ImageTransformer from "@/ts/ImageViewer/Transform/ImageTransformer";

export default class Zoom extends ImageTransformer {
    private touch: any;

    constructor(touch: any, elements: any) {
        super(elements);
        this.touch = touch;
    }

    public onTouchMove(event: TouchEvent) {
        const isDoubleTouch = event.touches.length === 2;

        if (!isDoubleTouch) {
            return
        }

        this.touch.last.eventType = 'zoom';
        const distance = this.calculateDistanceBetweenTouches(event.touches[0], event.touches[1]);

        const scale = this.touch.initial.scale * distance / this.touch.initial.distance;
        this.touch.current.scale = scale;

        requestAnimationFrame(() => {
            if (this.elements.image) {
                // Установить точку преобразования на среднюю точку между двумя пальцами
                this.elements.image.style.transformOrigin = `${this.touch.initial.midPointX}px ${this.touch.initial.midPointY}px`;
                // Учесть смещение изображения при масштабировании
                this.elements.image.style.transform = `translate(${this.touch.initial.offsetX}px, ${this.touch.initial.offsetY}px) scale(${scale})`;
            }
        });
    }

    public onTouchStart(event: TouchEvent) {
        const isDoubleTouch = event.touches.length === 2;
        if (!isDoubleTouch) {
            return;
        }
        this.touch.last.eventType = 'zoom';
        this.touch.initial.distance = this.calculateDistanceBetweenTouches(event.touches[0], event.touches[1]);
        this.touch.initial.scale = this.touch.current.scale;

        // Если изображение было увеличено, используем последние значения midPointX и midPointY
        if (this.touch.current.scale > 1) {
            this.touch.initial.midPointX = this.touch.last.midPointX;
            this.touch.initial.midPointY = this.touch.last.midPointY;
        } else {
            // Вычислить среднюю точку между двумя пальцами
            const midPointX = (event.touches[0].pageX + event.touches[1].pageX) / 2;
            const midPointY = (event.touches[0].pageY + event.touches[1].pageY) / 2;

            // Вычислить координаты средней точки относительно изображения
            const rect = this.elements.image!.getBoundingClientRect();
            this.touch.initial.midPointX = midPointX - rect.left;
            this.touch.initial.midPointY = midPointY - rect.top;

            // Сохраняем последние значения midPointX и midPointY
            this.touch.last.midPointX = this.touch.initial.midPointX;
            this.touch.last.midPointY = this.touch.initial.midPointY;
        }
    }

    private calculateDistanceBetweenTouches(touch1: Touch, touch2: Touch) {
        return Math.hypot(
            touch1.pageX - touch2.pageX,
            touch1.pageY - touch2.pageY
        );
    }
}
