import {nextTick, ref, Ref} from "vue";
export type PlayerAudioType = 'meditations'
export type PlayerMetadata = {
    image: string;
    title: string;
    audio: HTMLAudioElement;
    type?: PlayerAudioType;
    id?: number;
}
export default class BackgroundAudio {

    private static instance: BackgroundAudio;
    private static creating: boolean = false;
    public isPlaying: Ref<boolean> = ref(false);
    public isShowedPopup: Ref<boolean> = ref(false);
    private audio: HTMLAudioElement | null = null;
    public playerMetadata: Ref<PlayerMetadata | null> = ref(null);

    static getInstance() {
        if (!BackgroundAudio.instance) {
            BackgroundAudio.creating = true;
            BackgroundAudio.instance = new BackgroundAudio();
            BackgroundAudio.creating = false;
        }
        return BackgroundAudio.instance;
    }

    public addEventListener(event: string, listener: EventListener) {
        this.audio?.addEventListener(event, listener);
    }

    public removeEventListener(event: string, listener: EventListener) {
        this.audio?.removeEventListener(event, listener);
    }

    private getPlayerMetadata(): PlayerMetadata | null {
        return this.playerMetadata.value;
    }

    private constructor() {
        if (!BackgroundAudio.creating) {
            throw new Error("You must use BackgroundAudio.getInstance() to create an instance.");
        }
    }

    public setAudio(metadata: PlayerMetadata): this {
        this.stop();
        this.audio = metadata.audio;
        this.playerMetadata.value = metadata;
        return this;
    }

    private stop() {
        if(this.audio) {
            this.pause();
            this.audio.currentTime = 0;
        }
    }

    public async showPopup() {
        this.isShowedPopup.value = false
        await nextTick()
        this.isShowedPopup.value = true;
    }

    public hidePopup() {
        this.isShowedPopup.value = false;
    }

    private setPlayingState(isPlaying: boolean) {
        this.isPlaying.value = isPlaying;
    }

    public play() {
        this.setPlayingState(true);
        this.audio?.play();
    }

    public pause() {
        this.setPlayingState(false);
        this.audio?.pause();
    }
}
