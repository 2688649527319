import {initializeApp} from "firebase/app";
import {FirebaseApp} from "@firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import ApiClient from "@/ts/Utility/ApiClient";

export default class Firebase {

    private initialized: boolean = false;
    private static instance: Firebase;
    private static creating: boolean = false;
    private publicKey = "BAETXA8BpVefhWm-APRk1amooNdckq8EuDmiv4aaVymAvYyUTeBSpFhoxwCUhPDyMoNlzm72WehynqssGHhjrY0";
    private config = {
        apiKey: "AIzaSyDBEzzIAn_Mu6zsiITDSv11l3J5eeUSdwM",
        authDomain: "magic-moon-7100d.firebaseapp.com",
        projectId: "magic-moon-7100d",
        storageBucket: "magic-moon-7100d.appspot.com",
        messagingSenderId: "1088788765372",
        appId: "1:1088788765372:web:cf886cbc89f4fb7390ceb9"
    }

    private app: FirebaseApp | null = null;

    private constructor() {
        if (!Firebase.creating) {
            throw new Error("You must use AudioService.getInstance() to create an instance.");
        }
    }

    static getInstance() {
        if (!Firebase.instance) {
            Firebase.creating = true;
            Firebase.instance = new Firebase();
            Firebase.creating = false;
        }
        return Firebase.instance;
    }

    public async init() {
        if(this.initialized) return;
        this.initialized = true;

        this.app = initializeApp(this.config);
        if (!this.isTokenRegistered()) {
            await this.saveToken();
        }
    }

    private async saveToken( ) {
        if(!this.app) throw new Error('Firebase app is not initialized');
        const messaging = getMessaging(this.app);
        const token = await getToken(messaging, {vapidKey: this.publicKey});
        await this.saveTokenOnServer(token);
        localStorage.setItem('hasNotificationToken', 'true');
    }

    public async saveTokenOnServer(token: string) {
        await ApiClient.post('notification/token', {token});
    }

    private isTokenRegistered(): Boolean {
        const hasNotificationToken = localStorage.getItem('hasNotificationToken');
        if(hasNotificationToken) {
            return true;
        }
        return false;
    }
}
