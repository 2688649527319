<script setup lang="ts">
import CloseButton from "@/components/UI/Button/CloseButton.vue";
import Popup from "@/components/UI/Pupup/Popup.vue";

type Theme = 'light' | 'dark';

defineProps({
  fullContentSize: {
    type: Boolean,
    default: false
  },
  fixedContentSize: {
    type: Boolean,
    default: false
  },
  filledContentSize: {
    type: Boolean,
    default: false
  },
  theme: {
    type: String as () => Theme,
    default: 'light'
  }
});
const emit = defineEmits(["closeButtonClick"]);
const show = defineModel('show')
const close = () => {
  show.value = false;
}
const onCloseButtonClick = () => {
  close()
  emit('closeButtonClick');
}
</script>

<template>
  <Popup v-model:show="show" :theme="theme">
    <div class="popup__header">
      <CloseButton @click="onCloseButtonClick" :theme="theme"/>
    </div>
    <div class="popup__content" :class="{fixedContentSize, fullContentSize, filledContentSize}">
      <slot/>
    </div>
    <div class="popup__footer">
      <slot name="footer" class="popup__footer" :close="close" />
    </div>
  </Popup>
</template>

<style scoped lang="scss">

.popup {
  &__header {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
  }
  &__content {
    &.fixedContentSize {
      height: calc(100% - 248px);
      max-height: 518px;
    }
    &.fullContentSize {
      height: 100%;
    }
    &.filledContentSize{
      overflow: hidden;
    }
  }
}
</style>
