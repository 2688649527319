<script setup lang="ts">
import {computed} from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "#000",
  },
  size: {
    type: Number,
    default: 24,
  },
  name: {
    type: String,
    required: true,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  transition: {
    type: Boolean,
    default: true,
  },
})

const src = computed(() => require(`@/assets/icons/${props.name}.svg`));
const style = computed(() => ({
  fontSize: `${props.size}px`,
  maskImage: `url(${src.value})`,
  WebkitMaskImage: `url(${src.value})`,
  background: props.color,
  transform: `rotate(${props.rotate}deg)`,
  transition: props.transition ? "background-color 0.3s" : "none",
}));
</script>

<template>
  <i :style="style"></i>
</template>

<style scoped lang="scss">
i{
  display: block;
  width: 1em;
  height: 1em;
  mask-size:cover;
}
</style>
