<script setup lang="ts">

import Icon from "@/components/UI/Icon.vue";
import {computed, ref} from "vue";
type Theme = 'light' | 'dark';
const {theme} = defineProps({
  theme: {
    type: String as () => Theme,
    default: 'dark'
  }
});

const iconColor = computed(() => {
  return theme === 'light' ? '#FFFFFF' : '#363739';
});
</script>

<template>
  <div class="back-button" :class="[theme]">
    <Icon name="close" :size="12" :color="iconColor"/>
  </div>
</template>

<style scoped lang="scss">
.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 40px;
  border-radius: 30px;
  &.dark {
    background-color: #FFFFFF;
  }
  &.light{
    background-color: #363739;
  }
  &:hover {
    cursor: pointer;
  }
}
</style>
