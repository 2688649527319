import {Ref} from "vue";

type EventMap = {
    touchstart: (event: TouchEvent) => void;
    touchend: (event: TouchEvent) => void;
    touchmove: (event: TouchEvent) => void;
};

export default class Popup {

    private container: any | null = null;
    private startTouchVerticalPosition: number = 0;
    private initialized: boolean = false;
    private isSweepable: boolean = false;

    private onCloseCallback: () => void = () => {
    };

    private events: EventMap = {
        touchstart: this.onTouchStart.bind(this),
        touchend: this.onTouchEnd.bind(this),
        touchmove: this.onTouchMove.bind(this),
    }

    private original = {
        bodyStyle: {
            overscrollBehaviorY: '',
            overflow: '',
        },
    }

    constructor(private offsetForClose: number = 100, private activeUntilOffset: number = 0, private style?: Ref<object>) {
    }


    public init(container: HTMLElement): void {
        this.initialized = true;
        this.container = container;
        this.disableUselessEvents()
        this.initEvents();
    }

    public onClose(callback: () => void): void {
        this.onCloseCallback = callback;
    }

    private initEvents(): void {
        if(!this.container) return;
        Array.from(Object.keys(this.events)).forEach((eventName) => {
            this.container.addEventListener(eventName, this.events[eventName as keyof EventMap]);
        })
    }

    private onTouchStart(event: TouchEvent): void {
        const verticalPositionInsideContainer = event.touches[0].clientY - this.container.getBoundingClientRect().top;
        this.isSweepable = this.activeUntilOffset === 0 || verticalPositionInsideContainer <= this.activeUntilOffset
        this.startTouchVerticalPosition = event.touches[0].clientY;
    }

    private setOffsetY(offsetY: number): void {
        if(!this.style) return;
        this.style.value = {
            '--start-translate-y' : `${offsetY}px`
        }
    }
    private resetOffsetY(): void {
        if(!this.style) return;
        this.style.value = {  }
    }

    private onTouchMove(event: TouchEvent): void {
        if (!this.isSweepable) return;
        const currentTouchVerticalPosition = event.touches[0].clientY;
        const offsetY = currentTouchVerticalPosition - this.startTouchVerticalPosition;
        if (offsetY < 0) return;
        this.container.style.transform = `translateY(${offsetY}px) translateX(-50%) scale(1, 1)`;
        this.setOffsetY(offsetY);
    }

    private onTouchEnd(event: TouchEvent): void {
        if (!this.isSweepable) return;
        const endTouchVerticalPosition = event.changedTouches[0].clientY;
        const diff = this.startTouchVerticalPosition - endTouchVerticalPosition;
        const needClose = Math.abs(diff) > this.offsetForClose;
        if (needClose) {
            this.close();
        } else {
            this.resetOffsetY()
            this.restorePosition()
        }
    }

    private close(): void {
        this.onCloseCallback();
        this.restoreDefaultValues();
        setTimeout(() => {
            this.resetOffsetY()
        }, 600)
    }

    private restorePosition(): void {
        this.container.style.transform = `translateY(0px) translateX(-50%) scale(1, 1)`;
        this.container.classList.add('restoring');
        setTimeout(() => {
            this.container.classList.remove('restoring');
            this.container.style.transform = '';
        }, 300);
    }


    private disableUselessEvents(): void {
        this.original.bodyStyle = {
            overscrollBehaviorY: document.body.style.overscrollBehaviorY,
            overflow: document.body.style.overflow,
        };
        document.body.style.overscrollBehaviorY = 'none'
        document.body.style.overflow = 'hidden';
    }

    private restoreDefaultValues(): void {
        this.container.style.transform = '';
        document.body.style.overscrollBehaviorY = this.original.bodyStyle.overscrollBehaviorY;
        document.body.style.overflow = this.original.bodyStyle.overflow;
    }

    public destroy(): void {
        if (!this.initialized) return;
        this.restoreDefaultValues();
        Array.from(Object.keys(this.events)).forEach((eventName) => {
            this.container.removeEventListener(eventName, this.events[eventName as keyof EventMap]);
        })
    }
}
