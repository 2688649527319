import ApiClient from "@/ts/Utility/ApiClient";
import Audio from "@/ts/Audio/Audio";

export default class AudioRepository {
    public static async getAll(type: string): Promise<Audio[]> {
        return await ApiClient.get<Audio[]>(type);
    }
    public static async saveMeditationListenedEvent(id: number): Promise<void> {
        try {
            await ApiClient.post<void>(`/meditations/${id}/listened`);
        }catch (e) {
        }
    }
}
