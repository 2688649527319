import {Category, CategoryType, CustomCategory} from "@/ts/DayBook/Type/Category";
import ApiClient from "@/ts/Utility/ApiClient";
import StaticCategoryRepository from "@/ts/DayBook/Repository/Category/StaticCategoryRepository";

export default class CategoryRepository {
    static async findAll(): Promise<Category[]> {
        return await ApiClient.get<Category[]>('daybook/categories');
    }

    static async find(id: string): Promise<CustomCategory> {
        return await ApiClient.get<CustomCategory>(`daybook/custom/category/${id}`);
    }

    static async removeCategory(category: CategoryType): Promise<void> {
        await StaticCategoryRepository.removeCheckedCategory(category)
        return await ApiClient.delete(`daybook/categories/${category}`);
    }

    static async removeCustomCategory(id: string): Promise<void> {
        return await ApiClient.delete(`daybook/categories/custom/${id}`);
    }

    static async create(name: string): Promise<void> {
        await ApiClient.post('daybook/categories', {name});
    }

    static async hasNotEmptiedOrChecked(): Promise<boolean> {
        if (StaticCategoryRepository.hasSomeCheckedCategory()) {
            return true;
        }
        const checkedCategories = await CategoryRepository.findNotEmptiedOrChecked();
        if (checkedCategories.length > 0) {
            await StaticCategoryRepository.setCheckedCategories(checkedCategories.map(category => category.type));
            return true;
        }
        return false;
    }

    static async findNotEmptiedOrChecked(): Promise<Category[]> {
        const checkedCategories = StaticCategoryRepository.findAllCheckedCategoryTypes();
        return (await CategoryRepository.findAll())
            .filter(category => category.count! > 0 || category.type === 'custom' || this.isCheckedCategory(checkedCategories, category))
    }

    static async findUncheckedCategoriesAndEmpty(): Promise<Category[]> {
        const checkedCategories = StaticCategoryRepository.findAllCheckedCategoryTypes();
        return (await CategoryRepository.findAll())
            .filter(category => !(category.count! > 0 || category.type === 'custom' || this.isCheckedCategory(checkedCategories, category)))
    }

    static async findCheckedCategories(): Promise<Category[]> {
        const checkedCategories = StaticCategoryRepository.findAllCheckedCategoryTypes();
        return (await CategoryRepository.findAll())
            .filter(category => this.isCheckedCategory(checkedCategories, category))
    }

    private static isCheckedCategory(checkedCategories: (CategoryType | string)[], category: Category): boolean {
        return checkedCategories.includes(category.type) || category.type === 'custom' && checkedCategories.includes(category.id!)
    }
}
