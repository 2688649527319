<script setup lang="ts">
import CDN from "@/ts/Utility/CDN";
import {onMounted, onUnmounted, ref} from "vue";
import ImageViewer from "@/ts/ImageViewer/ImageViewer";

const container = ref<HTMLDivElement>( )
const image = ref<HTMLImageElement>( )
const log = ref<string>("")
const closing = ref<boolean>(false)
const viewer = ImageViewer.getInstance()
const init = () => {
  viewer.init(container.value!,image.value!)
}
onMounted(() => {
  init()
})
onUnmounted(() => {
  viewer.destroy()
})
</script>

<template>
<div id="image-viewer" ref="container"  >
  <img  alt="image" ref="image">
</div>
</template>

<style scoped lang="scss">
@keyframes closeViewer {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes closeViewerImage {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes openViewer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes openViewerImage {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
#image-viewer {
  display: none;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #232529;
  z-index: 1000;
  &.showing {
    animation: openViewer 0.5s forwards;
    img{
      animation: openViewerImage 0.5s forwards;
    }
  }
  &.show {
    display: block;
  }
  &.closing {
    animation: closeViewer 0.5s linear forwards;
    img{
      animation: closeViewerImage 0.5s forwards;
    }
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s ease-out;
  }
  .logger{
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    background-color: rgba(0,0,0,0.5);
    z-index: 1001;
  }
}
</style>
