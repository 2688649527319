<script setup lang="ts">
</script>

<template>
  <div class="audio-wave">
    <img src="@/assets/animation/wave.svg" alt="wave"/>
  </div>
</template>

<style scoped lang="scss">
.audio-wave {
  width: 70px;
  height: 70px;
  position: fixed;
  border-radius: 30px;
  bottom: 100px;
  z-index: 100;
  left: 50%;
  transform: translateX(100px);
  background: linear-gradient(91.59deg, #FFBA35 0%, #FD9F30 100%);
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 100%;
    height: 24px;
    border-radius: 30px;
  }
  &:hover{
    cursor: pointer;
  }
}
</style>
