import {Category, CategoryType} from "@/ts/DayBook/Type/Category";

export default class StaticCategoryRepository {

    private static CATEGORY_MAP: Map<CategoryType, string> = new Map([
        ['self_reflection', "Саморефлексия"],
        ['achievement', "Достижения"],
        ['intentions', "Намерения"],
        ['acknowledgments', "Благодарности"],
        ['books', "Книги"],
        ['talents', "Таланты"],
        ['tracker', "Трекер"],
        ['desires', "Желания"],
        ['trips', "Путешествия"],
        ['big_money', "Большие деньги"],
        ['custom', "Пользовательская категория"],
    ]);

    static async find(type: CategoryType): Promise<Category> {
        return {
            type: type,
            name: this.CATEGORY_MAP.get(type) as string
        }
    }

    static async findAll(): Promise<Category[]> {
        const types = Array.from(this.CATEGORY_MAP.keys());
        return Promise.all(types.map(type => this.find(type)));
    }

    static async setCheckedCategories(types: CategoryType[]): Promise<void> {
        localStorage.setItem('checkedCategories', JSON.stringify(types));
    }

    static async addCheckedCategory(type: CategoryType): Promise<void> {
        const storedCategories = localStorage.getItem('checkedCategories');
        const categories = storedCategories ? JSON.parse(storedCategories) as CategoryType[] : [];
        categories.push(type);
        localStorage.setItem('checkedCategories', JSON.stringify(categories));
    }

    static async removeCheckedCategory(type: CategoryType): Promise<void> {
        const storedCategories = localStorage.getItem('checkedCategories');
        const categories = storedCategories ? JSON.parse(storedCategories) as CategoryType[] : [];
        const index = categories.indexOf(type);
        if (index !== -1) {
            categories.splice(index, 1);
        }
        localStorage.setItem('checkedCategories', JSON.stringify(categories));
    }

    static findAllCheckedCategoryTypes(): (CategoryType | string)[] {
        const storedCategories = localStorage.getItem('checkedCategories');
        return storedCategories ? JSON.parse(storedCategories) as (CategoryType | string)[] : [];
    }

    static hasSomeCheckedCategory(): boolean {
        return this.findAllCheckedCategoryTypes().length > 0;
    }

}
